import StatsTracker from "./statsTracker";
import createElementChangeListener from "../elementChangeListener";
import createTimer from "./resumableTimer";
import {
  PAGE_LOAD_EVENT,
  READ_ARTICLE_EVENT,
  URL_CHANGE_EVENT,
  POST_SHARED_EVENT,
} from "../consts";

function checkForDuplicatedPosts(post){
  const postId = post.getAttribute("elid");
  return SPLIT_TESTED_POSTS.some(splitedPost => splitedPost.post_id === postId);
}

function trackPostCardClick(post) {
  function onLinkClick(event){
    const currentTarget = event.currentTarget;
    const isTargetBlank = currentTarget.getAttribute("target") === "_blank";
    const isPagination = currentTarget.classList.contains(
      "js-listicle-switch-page"
    );

    if (!isTargetBlank) {
      event.preventDefault();
    }
    setTimeout(() => StatsTracker.trackPostCardClick(post), 0);
    if (!isTargetBlank && !isPagination) {
      // @NOTE: check if we redirect on the same path ignore it
      if(event.metaKey || event.ctrlKey){
        window.open(currentTarget.href)
      }
      else {
        document.location.href = currentTarget.href;
      }
    }
  }

  function forEachLink(link){
    if (!/^(https?:)?\/\/\w+/.exec(link.href) || link.classList.contains('rm-stats-tracked')) {
      return;
    }
    link.classList.add('rm-stats-tracked');
    link.addEventListener("click", onLinkClick);
  }


  post.querySelectorAll("a[href]:not(.rm-stats-tracked)").forEach(forEachLink);
}

function trackPostCardView(post){
  function onCardVisible(){
    if (!SPLIT_TESTED_POSTS.length || (SPLIT_TESTED_POSTS.length && !checkForDuplicatedPosts(post))) {
      StatsTracker.trackPostCardView(post);
    }
  }

  function isCardVisible(postCard){
      if (postCard.isIntersecting) {
        onCardVisible();
      }
  };

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.8,
  };

  function callback (entries, observer) {
    entries.forEach(isCardVisible);
  };

  const observer = new IntersectionObserver(callback, options);

  function forEachCard(postCard){
    if(postCard.classList.contains('rm-stats-tracked')){
      return
    }
    postCard.classList.add('rm-stats-tracked');
        observer.observe(postCard);
   }

  post.querySelectorAll("article:not(.rm-stats-tracked)").forEach(forEachCard);
}

function initPostsTracker() {
  createElementChangeListener("[elid]", (post) => {
    trackPostCardClick(post);
    trackPostCardView(post);
  });
}

function initTimeOnPageTracker() {
  const maxTotalDuration = 30 * 60 * 1000;
  const reportTimeFrames = [1, 2, 5, 10, 20, 30].map((frame) => frame * 1000);

  const timer = createTimer(
    StatsTracker.trackTimeOnPage,
    reportTimeFrames,
    maxTotalDuration
  );

  timer.start();

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      timer.start();
    } else {
      timer.pause();
    }
  });
}

function initUrlChangeListeners(context) {
  const { eventBus } = context;
  let initialPageUrl = "";

  eventBus.subscribe(READ_ARTICLE_EVENT, (data) => {
    if (data.trackPageView) {
      StatsTracker.trackPageView(data);
    } else {
      StatsTracker.trackEvent({
        ...data,
        eventType: "keep_reading",
        action: "view_more",
      });
    }
  });

  eventBus.subscribe(PAGE_LOAD_EVENT, (data) => {
    StatsTracker.trackPageLoad(data, true);
    StatsTracker.trackUser(data);
    initialPageUrl = data.url;
  });

  eventBus.subscribe(URL_CHANGE_EVENT, (data) => {
    if (data.url !== initialPageUrl && data.trackPageView) {
      data.previousUrl = initialPageUrl;
      StatsTracker.trackPageView(data);
      initialPageUrl = data.url;
    }
  });
}

function initPostsShareListener({ eventBus, settings }) {
  eventBus.subscribe(POST_SHARED_EVENT, (data) => {
    StatsTracker.trackSocialShare({
      provider: data.provider,
      id: settings.post.id,
    });
  })
}

const SPLIT_TESTED_POSTS = [];

function onSplitTestedPost(scriptElement) {
  const contextData = JSON.parse(scriptElement.text);
  if ("current" in contextData.post.split_testing) {
    const { campaign_id, hypothesis_id } = contextData.post.split_testing.current;
    SPLIT_TESTED_POSTS.push({
      post_id: parseInt(scriptElement.closest("article[elid]").getAttribute("elid"), 10),
      campaign_id: campaign_id,
      hypothesis_id: hypothesis_id,
    });
  }
}

function fireBatchPostCardView(event) {
  if (
    SPLIT_TESTED_POSTS.length > 0 &&
    (event.type === "pagehide" || document.visibilityState === "hidden")
  ) {
    StatsTracker.batchPostCardView(SPLIT_TESTED_POSTS);
    SPLIT_TESTED_POSTS.length = 0;
  }
}

function initBatchPostCardView() {
  createElementChangeListener("script.post-context-split-testing", onSplitTestedPost);
  window.addEventListener("visibilitychange", fireBatchPostCardView, { passive: true });
  window.addEventListener("pagehide", fireBatchPostCardView, { passive: true });
}

export default function statsProvider(context) {
  initUrlChangeListeners(context);
  initPostsTracker();
  initTimeOnPageTracker();
  initPostsShareListener(context);
  initBatchPostCardView(context);
  return context;
}
